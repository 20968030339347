// If you want to override variables do it here
@import 'variables';

// Import styles
@import '~@coreui/coreui/scss/coreui.scss';

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import 'custom';

// ie fixes
@import 'ie-fix';

.btn-success {
   color: #fff;
   background-color: #79ad3f;
   border-color: #79ad3f;
}

.btn-success:hover {
   color: #fff;
   background-color: #5a8a25;
   border-color: #5a8a25;
}
.bg-success {
   background-color: #154e63 !important;
}

//IMDECO
.bg-primary {
   background-color: #e6e6e6 !important;
}
.card.bg-primary {
   border-color: #e6e6e6;
   background-color: #e6e6e6;
}

.btn-info {
   color: #fff;
   background-color: #3faaca;
   border-color: #3faaca;
}

.btn-info:hover {
   color: #fff;
   background-color: #328ca7;
   border-color: #328ca7;
}

// .btn-outline-secondary {
//    color: #4f6f8a;
//    background-color: transparent;
//    background-image: none;
//    border-color: #4f6f8a;
// }

// .btn-outline-secondary:hover {
//    color: #fff;
//    background-color: #4f6f8a;
//    background-image: none;
//    border-color: #4f6f8a;
// }

a {
   color: #2d5972;
}

.sidebar .nav-link:hover {
   color: #fff;
   background: #3b4044;
}
.sidebar .nav-link.active {
   color: #fff;
   background: #464d52;
}

html body .app.flex-row.align-items-center {
   height: 100vh;
   background-color: #cecece;
}
.page-item.active .page-link {
   z-index: 0;
   color: #fff;
   background-color: #20a8d8;
   border-color: #20a8d8;
}

.table-bordered th,
.table-bordered td {
   border: none;
}

.table thead tr {
   background-color: #dedede;
}

.form-control-calendar {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
}

.btn:focus,
.btn:active {
   outline: none !important;
   box-shadow: none;
}

.btn-group.special {
   display: flex;
}

.special .btn {
   flex: 1;
}

//Custon Color
.btn-primary {
   color: #fff;
   background-color: #2e5972;
   border-color: #2e5972;
}

.btn-primary:hover {
   color: #fff;
   background-color: #274c61;
   border-color: #274c61;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
   color: #fff;
   background-color: #1d3a4a;
   border-color: #1d3a4a;
}

.btn-secondary {
   color: #fff;
   background-color: #f7914c;
   border-color: #f78e48;
}

.btn-secondary:hover {
   color: #fff;
   background-color: #e07f3e;
   border-color: #df7e3e;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
   color: #fff;
   background-color: #ce753a;
   border-color: #ce753a;
}

@include media-breakpoint-down(xs) {
   .main .container-fluid {
      padding: 0 15px;
   }

   .breadcrumb {
      margin-bottom: 1.2rem;
   }
}

/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
   position: fixed;
   margin: auto;
   width: 500px;
   height: 100%;
   -webkit-transform: translate3d(0%, 0, 0);
   -ms-transform: translate3d(0%, 0, 0);
   -o-transform: translate3d(0%, 0, 0);
   transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
   height: 100%;
   overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
   padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog {
   left: 0px;
   -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
   -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
   -o-transition: opacity 0.3s linear, left 0.3s ease-out;
   transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
   left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
   right: 0px;
   -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
   -o-transition: opacity 0.3s linear, right 0.3s ease-out;
   transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
   right: 0;
}

.modal.right .modal-content {
   border-radius: 0;
   border: none;
}

.modal.right .modal-header {
   border-bottom-color: #eeeeee;
   background-color: #fafafa;
}
